<template>
  <div class="">
    <div v-if="isDateCell">
      <span v-if="!customSignalRange.startDate">-</span>
      <date-range-picker
        ref="picker"
        v-model="customSignalRange"
        append-to-body
        :single-date-picker="true"
        :ranges="false"
        :locale-data="{ firstDay: 1, format: 'mm-dd-yy' }"
        opens="right"
        @update="changeDateValue"
      />
    </div>

    <div
      v-else
      style=""
    >
      <t-input
        v-model="result"
        style="background:transparent; border: none;"
        class="tasqs-filter-input text-textgray"
      />
    </div>
  </div>
</template>
<script>
import debounce from 'lodash.debounce';
import metaDataModule from '@/store/modules/metaDataModule';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manuallyp
export default {
  components: {
    DateRangePicker,
  },

  data() {
    return {
      selectedTags: [],
      wellTags: [],
      cancelBeforeStart: true,
      availableTags: [],
      highlightAllOnFocus: true,
      value: [],
      tagID: '',
      objectID: '',
      customSignalRange: { startDate: null, endDate: null },
      isDateCell: false,
      result: '',
      fieldName: '',
      attributeID: null,
      dataLoaded: false,
    };
  },

  watch: {
    async result(...arg) {
      // console.log(this.result)
      if (this.dataLoaded) {
        await this.debouncedWatch(...arg);
      }
    },
  },
  async beforeMount() {

  },

  async created() {
    this.debouncedWatch = debounce(async (newValue, oldValue) => {
      if (this.attributeID) {
        await metaDataModule.putNodeAttribute({ id: this.attributeID, node: this.objectID, val: this.result });
      } else {
        await metaDataModule.postNodeAttribute({ attributeId: this.fieldName, node: this.objectID, val: this.result });
      }
    }, 2500);

    const objectID = this.params.data.Well;
    this.objectID = objectID;
    const fieldName = this.params.colDef.colId;
    const knowAttributes = metaDataModule.knownAttributes;
    //  console.log(knowAttributes)
    if (knowAttributes) {
      const currentAttribute = (knowAttributes.find((attr) => attr.ID === fieldName));
      if (currentAttribute && currentAttribute.AttributeTypeName === "TIMESTAMP" ) {
        console.log(currentAttribute.AttributeTypeName);
         this.isDateCell = true;
      }
    }
    // eslint-disable-next-line prefer-destructuring
    this.fieldName = fieldName;
    // console.log(fieldName)
 
    // console.log(this.params.colDef.field)
    const response = await metaDataModule.fetchTableAttributes({ node: objectID, knownAttributeID: this.fieldName });
    if (response && response.Val) {
      // console.log(response);

      this.result = response.Val;
      this.attributeID = response.ID;
      if (this.isDateCell) {
        this.customSignalRange = { startDate: new Date(response.Val), endDate: new Date(response) };
      }
    }

    setTimeout(() => {
      this.dataLoaded = true;
    }, 5000);
  },

  beforeUnmount() {
    this.debouncedWatch.cancel();
  },

  isPopup() {
    return true;
  },

  methods: {

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async changeDateValue(value,) {
      console.log(value)
      const updatedDate = (new Date(value.startDate.getFullYear(), value.startDate.getMonth(), value.startDate.getDate()).toDateString());
    await metaDataModule.postNodeAttribute({ node: this.objectID, attributeId: this.fieldName, val: updatedDate });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>;

<style scoped>
.customUI >>> .multiselect__tags {
    background: #283751;
    border-color: transparent;
    max-height: 35px;
    padding: 4px;
}

.customUI >>> .multiselect__select {
  display: none;
}

.customUI >>> .multiselect__tag {
  background: rgb(61,153,170);
}

.customUI >>> .multiselect__content-wrapper{
  position: fixed;
}

.customUI >>> .multiselect__option--highlight {
  background:rgb(61,153,170);
}

.customUI >>> .multiselect__tag-icon:after{
  background:rgb(61,153,170);
}

.customUI >>> i.multiselect__tag-icon{
  background:rgb(61,153,170);
}

.customUI >>> input {
  background: #283751;
  color: white;
}

.customUI >>> .multiselect__option--highlight:after{
  background:rgb(61,153,170);
  display: none;
}
.customUI >>> .multiselect__content-wrapper {
  position: fixed;

    position: fixed;
    z-index: 99999 !important;
    height: 150px !important;
  top: 50px;
  width: 300px;

}

.customUI >>> .multiselect--active {
  z-index: 10;
}

.vue-daterange-picker >>> .reportrange-text {
  background: transparent;
  border: none;
  width: 150px;
  height: 35px;
}
</style>
